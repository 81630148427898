export default value => {

    var copyText = document.createElement("input");
    
    // Place in top-left corner of screen regardless of scroll position.
    copyText.style.background = 'transparent';
    copyText.style.width = '2em';
    copyText.style.height = '2em';

    copyText.value = value;

    document.body.appendChild(copyText);
    //copyText.focus();
    copyText.select();
    
    document.execCommand('copy');

    document.body.removeChild(copyText);
    window.getSelection().removeAllRanges();
};