import Vue from 'vue';

import uppercase from './uppercase';//import uppercase from './filters/uppercase';
Vue.filter('uppercase', uppercase);

import formatPrice from './formatPrice';
Vue.filter('formatPrice', formatPrice);

//Show front end date 
import formatDate from './formatDate';
Vue.filter('formatDate',formatDate);

//Copy clip board
import copyClipBoard from './copyClipBoard';
Vue.filter('copyClipBoard',copyClipBoard);

//check minimum and maximum value 
import minMax from './minMax';
Vue.filter('minMax',minMax);

//Only Number allowed
import numberAllowed from './numberAllowed';
Vue.filter('numberAllowed',numberAllowed);

//Only decimal value allowed
import decimalAllowed from './decimalAllowed';
Vue.filter('decimalAllowed',decimalAllowed);

// convert the given text to a slug
import slugify from './changeStringToSlugify';
Vue.filter('slugify',slugify);

//validate enter email
import validateEmail from './validateEmail';
Vue.filter('validateEmail', validateEmail);

//decimal with negative value allow
Vue.filter('decimalAllowedWithNegative', function (event) {
    let evt = event;  
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    
    if (evt.shiftKey == true) {
      //evt.preventDefault();
    }
    //this.minMax(event);
    if(evt.target.value.indexOf('.') !== -1 && (charCode == 190 || charCode == 110))
      evt.preventDefault();
    //evt.target.value.split('.')[0].length

    //evt.target.value = evt.target.value.toFixed(2);
    if (charCode == 109 || (charCode >= 48 && charCode <= 57) || 
        (charCode >= 96 && charCode <= 105) || 
        charCode == 8 || charCode == 9 || charCode == 37 ||
        charCode == 39 || charCode == 46 || charCode == 190 || charCode == 110 || charCode == 116) {
      return true;
    } else {
      evt.preventDefault();
    }
});

//readmore
Vue.filter('readMore', function (text, length, suffix) {
    return text.substring(0, length) + suffix;
});
//flash hide after 5 sec.
Vue.filter('flashHide', function (e) {
  setTimeout(function(){ e.alert = false;e.flash.error = false;e.flash.message=''; }, 5000);
});

//capitalize value
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
});


Vue.filter('indexWhere', function(aItems,conditionFn) {
  const item = aItems.find(conditionFn)
  return aItems.indexOf(item)
});

Vue.filter('showPriceFormat', function (price,currency) {
    return currency+parseFloat(price).toFixed(2);
});

Vue.filter('nl2br', function (str, is_xhtml) {   
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
});