export default event => {
  let evt = event;  
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  //event.target.value = event.target.value.replace(/[^\d]/g, '');
  //if ((charCode > 31 && ((charCode < 48  && (charCode !== 37 && charCode !== 39)) || charCode > 57)) && charCode !== 46 && charCode !== 116) {
  
  if ((charCode >= 48 && charCode <= 57) || 
      (charCode >= 96 && charCode <= 105) || 
      charCode == 8 || charCode == 9 || charCode == 37 ||
      charCode == 39 || charCode == 46 || charCode == 116) {
    return true;
  } else {
    evt.preventDefault();
  }
};