<template src="./Templates/Select2.html"></template>
<style>
.select2-hidden{display:none;}
.select2-container{z-index:9999;}
.select2.select2-container{width:100% !important;}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple{background-color: #f7f7f7 !important;font-size: 13px;min-height: 40px;padding: .375rem 2.25rem .375rem .75rem;}
.select2-container .select2-selection--single .select2-selection__rendered,.select2-container--default .select2-selection--multiple .select2-selection__rendered{padding:0px;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li{background-color: #f7f7f7 !important;}
.select2-container--default .select2-search--inline .select2-search__field{font-size:13px;}
.select2-container--default .select2-selection--single .select2-selection__arrow{background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
background-repeat: no-repeat;height: 13px;top: 12px;}
.select2-container--default .select2-selection--single .select2-selection__arrow b{display:none;}
.select2-results__option{color: #000;}
.select2-container--default .select2-results__option[aria-selected="true"]{display:none;}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li{background-color: #fff;color: #000;font-size: 10px;padding: 2px 2px 2px 2px;position: relative;}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{position: absolute;top: -6px;right: -7px;background: red;color: #fff;border-radius: 22px;font-size: 10px;width: 12px;text-align: center;height: 11px;line-height: 11px;}
</style>
<script>

export default {
      props:['options', 'value', 'name','cls','placeholder','type','tag','except','hideSearch','allowFreeTag','ajax','id'],
      data(){
            return{
              sObject:null
            }
        },
      mounted: function () {
        var vm = this
        console.log(this.except," except valid");
        let select2Obj = { data: this.options ,
                              placeholder: vm.placeholder,
                              tags: vm.tag,
                              minimumResultsForSearch: vm.hideSearch?-1:1,
                              tokenSeparators: [','],
                              createTag: function (params) {
                                var value = params.term;
                                if(vm.tag){
                                  if(vm.allowFreeTag){
                                    return {
                                        id: params.term,
                                        text: params.term
                                      }
                                  }else{
                                    if(vm.$options.filters.validateEmail(value) && $.inArray(value,vm.except)=='-1'){
                                      return {
                                        id: params.term,
                                        text: params.term,
                                        newOption: true
                                      }  
                                    }
                                  }
                                }else{
                                  return {
                                      id: params.term,
                                      text: params.term,
                                      newOption: true
                                    }
                                }
                                
                              },
                              escapeMarkup: function (m) {
                                return m;
                              },
                              templateResult: function (option) {
                                var originalOption = option.element;
                                if (!option.id) { return option.text; }

                                var $result = $("<span></span>");

                                $result.text(option.text);

                                if (option.newOption) {
                                  return $result.append(" <em>(new)</em>");
                                }else if(option.icon){
                                  return '<div  style="display: flex;align-items: center;"><span class="iti-flag '+((option.icon).toLowerCase())+'" style="margin-right:8px;display:block"></span> '+ option.text +'</div>';  // replace image source with option.img (available in JSON)
                                }else if(option.img){
                                  return '<img src="'+option.img+'" width="25px" style="margin-right:8px;vertical-align: middle;" /> '+ option.text ;  // replace image source with option.img (available in JSON)
                                }else if($(option.element).data('img')){
                                  return '<img src="'+$(option.element).data('img')+'" width="25px" style="margin-right:8px;vertical-align: middle;" /> '+ option.text ;  // replace image source with option.img (available in JSON)
                                }else if($(option.element).data('rate')){
                                  if($(option.element).data('rate')>0){
                                    return $result;
                                  }else{
                                    return '<span style="border:1px solid red;padding:2px;">'+option.text+'</span><em style="color:red">(expert rate is greater with client)</em>';
                                  }
                                }
                                return $result;
                              },
                              templateSelection: function(option) {
                                if(option.img){
                                  return '<img src="'+option.img+'" width="25px" style="margin-right:8px;vertical-align: middle;" /> '+ option.text ;  // replace image source with option.img (available in JSON)
                                }else if(option.icon){
                                  return '<div  style="display: flex;align-items: center;"><span class="iti-flag '+((option.icon).toLowerCase())+'" style="margin-right:8px;display:block"></span> '+ option.text +'</div>';  // replace image source with option.img (available in JSON)
                                }else if($(option.element).data('img')){
                                  return '<img src="'+$(option.element).data('img')+'" width="25px" style="margin-right:8px;vertical-align: middle;" /> '+option.text;
                                }else if($(option.element).data('rate')){
                                  if($(option.element).data('rate')>0){
                                    return option.text;
                                  }else{
                                    return '<span style="border:1px solid red;padding:2px;">'+option.text+'</span>';    
                                  }
                                }
                                return option.text;
                              },
                          };
        if(this.ajax !== undefined){
          select2Obj['minimumInputLength'] = 2;
          select2Obj['ajax'] =  {
                                  url: this.ajax.url,
                                  headers: this.axios.defaults.headers.common,
                                  dataType: 'json',
                                  delay: 250,
                                  data: function (data) {
                                    console.log(data,'asdasdasdasdasdasdasdasdasdasd')
                                      return {
                                          searchTerm: data.term // search term
                                      };
                                  },
                                  processResults: function (response) {
                                      console.log(response,'processresults')
                                      return {
                                          results:response.data
                                      };
                                  },
                                  cache: true
                              };
        }
        vm.sObject = $(this.$el).select2(select2Obj)
                          .val(this.value)
                          .trigger('change')
                          // emit event on change.
                          .on('change', function () {
                            vm.$emit('input', $(this).val(),vm.sObject);
                            //vm.$emit('input', this.value)
                          });
      },
      watch: {
        value: function (value) {
          if(this.id != null && this.id == 'service'){
            $('#add_on').val('').trigger('change').select2();
          }
          if(value !== null){
            // update value
            if ([...value].sort().join(",") !== [...$(this.$el).val()].sort().join(","))
              $(this.$el).val(value).trigger('change');
          }
        },
        options: function (options) {
          // update options
          //options
          $(this.$el).empty().select2({ data: options ,placeholder: this.placeholder,})
        }
      },
      destroyed: function () {
        $(this.$el).off().select2('destroy')
      }
}
</script>