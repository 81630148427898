export default (event,oldVal) => {

  let evt = event; 
  const value = evt.target.value;
  let val = evt.target.value.split('.');
  /*let max = evt.target.getAttribute('max').split('.');
  let min = evt.target.getAttribute('min').split('.');*/
  
  if((evt.target.getAttribute('min') || evt.target.getAttribute('max')) && val.length>0){
    if(evt.target.value.length==0){
      return parseFloat(evt.target.getAttribute('min'));
    }else if(parseFloat(evt.target.value) < parseFloat(evt.target.getAttribute('min'))){
      return evt.target.value = oldVal;
    }else if(parseFloat(evt.target.value) > parseFloat(evt.target.getAttribute('max'))){
      if(val.length>1){
        if(value == oldVal){
          return evt.target.value = val[0].substring(0,(val[0].length-1))+'.'+val[1];  
        }else{
          return evt.target.value = oldVal;
        }
      }else{
        if(value == oldVal){
          return evt.target.value = val[0].substring(0,(val[0].length-1));
        }else{
          return evt.target.value = oldVal;
        }
      }
    }
  }
  return evt.target.value;
};