export default event => {
  let evt = event;  
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  
  if (evt.shiftKey == true) {
    //evt.preventDefault();
  }
  //this.minMax(event);
  if(evt.target.value.indexOf('.') !== -1 && (charCode == 190 || charCode == 110))
    evt.preventDefault();
  //evt.target.value.split('.')[0].length

  //evt.target.value = evt.target.value.toFixed(2);
  if ((charCode >= 48 && charCode <= 57) || 
      (charCode >= 96 && charCode <= 105) || 
      charCode == 8 || charCode == 9 || charCode == 37 ||
      charCode == 39 || charCode == 46 || charCode == 190 || charCode == 110 || charCode == 116) {
    return true;
  } else {
    evt.preventDefault();
  }
};